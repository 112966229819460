<script setup>
import { computed, ref } from 'vue';
import { TipoPerfilEnum } from '~/enums/tipoPerfilEnum';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';

const props = defineProps({
  menu: {
    type: String,
    required: true,
  },
});

const authStore = useAuthStore();
const openKeys = ref(['gestao']);

const novasImportacoesHabilitado = computed(() =>
{
  return authStore.temPerfilAcesso(PERFIL_ACESSO.ImportarNovasPastasPelaPlanilhaBradesco);
});

const menuRobosHabilitado = computed(() =>
{
  return authStore.temPerfilAcesso([
    TipoPerfilEnum.ExecutarRoboConsultaGcpj,
    TipoPerfilEnum.ExecutarRoboEnvioDocumentosGcpj,
    TipoPerfilEnum.ExecutarRoboAtualizacaoGcpj,
    TipoPerfilEnum.ExecutarRoboEncerramentoGcpj,
  ]);
});
</script>

<template>
  <a-menu
    v-model:openKeys="openKeys"
    class="page-menu"
    mode="inline"
    :selected-keys="[props.menu]"
    :inline-indent="30"
  >
    <a-menu-item
      v-if="authStore.ehGestor || authStore.ehGerente"
      key="visao-geral"
    >
      <router-link to="/visao-geral">
        Visão Geral
      </router-link>
    </a-menu-item>
    <a-menu-item key="area-trabalho">
      <router-link to="/">
        Área de trabalho
      </router-link>
    </a-menu-item>
    <a-menu-item key="pastas">
      <router-link to="/pastas">
        Pastas
      </router-link>
    </a-menu-item>
    <a-menu-item key="agenda">
      <router-link to="/agenda">
        Agenda
      </router-link>
    </a-menu-item>
    <a-menu-item key="atualizacoes-gcpjs">
      <router-link to="/atualizacoes-gcpjs">
        Atualizações GCPJs
      </router-link>
    </a-menu-item>
    <a-menu-item key="fluxos">
      <router-link to="/fluxos">
        Fluxos
      </router-link>
    </a-menu-item>
    <a-menu-item key="compromissos">
      <router-link to="/compromissos">
        Compromissos
      </router-link>
    </a-menu-item>
    <a-menu-item key="publicacoes">
      <router-link to="/publicacoes">
        Publicações
      </router-link>
    </a-menu-item>
    <a-menu-item key="andamentos">
      <router-link to="/andamentos">
        Andamentos
      </router-link>
    </a-menu-item>
    <a-menu-item key="relatorios">
      <router-link to="/relatorios">
        Relatórios
      </router-link>
    </a-menu-item>
    <a-menu-item
      v-if="
        authStore.temPerfilAcesso(PERFIL_ACESSO.Administrador)
          || authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
          || authStore.temPerfilAcesso(PERFIL_ACESSO.Gcpj)
      "
      key="importacoes"
    >
      <router-link to="/importacoes">
        Importações
      </router-link>
    </a-menu-item>
    <a-menu-item
      v-if="novasImportacoesHabilitado"
      key="novas-importacoes"
    >
      <router-link to="/novas-importacoes">
        Novas Importações
      </router-link>
    </a-menu-item>
    <a-menu-item key="usuarios">
      <router-link to="/usuarios">
        Usuários
      </router-link>
    </a-menu-item>
    <a-menu-item key="contatos">
      <router-link to="/contatos">
        Contatos
      </router-link>
    </a-menu-item>
    <a-menu-item key="agencias">
      <router-link to="/agencias">
        Agências
      </router-link>
    </a-menu-item>
    <a-menu-item
      v-if="menuRobosHabilitado"
      key="robos"
    >
      <router-link to="/robos">
        Robôs
      </router-link>
    </a-menu-item>

    <a-menu-item-group
      v-if="
        authStore.temPerfilAcesso(
          PERFIL_ACESSO.AutomatizacaoConcluirFluxoEmLote,
        )
      "
      key="automatizacoes"
      title="AUTOMATIZAÇÕES"
    >
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(
            PERFIL_ACESSO.AutomatizacaoConcluirFluxoEmLote,
          )
        "
        key="concluir-fluxo-em-lote"
      >
        <router-link to="/automatizacoes/concluir-fluxo-em-lote">
          Concluir fluxos
        </router-link>
      </a-menu-item>
    </a-menu-item-group>

    <a-menu-item-group
      v-if="authStore.ehGestor || authStore.ehGerente"
      key="gestao"
      title="GESTÃO"
    >
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteGrcp)
            || authStore.ehGestor
        "
        key="grcp"
      >
        <router-link to="/gestao/grcp">
          GRCP
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteGrv)
            || authStore.ehGestor
        "
        key="grv"
      >
        <router-link to="/gestao/grv">
          GRV
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerentePortoVelho)
            || authStore.ehGestor
        "
        key="porto-velho"
      >
        <router-link to="/gestao/porto-velho">
          Porto Velho
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteBelem)
            || authStore.ehGestor
        "
        key="belem"
      >
        <router-link to="/gestao/belem">
          Belém
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteConsolidacao)
            || authStore.ehGestor
        "
        key="consolidacao"
      >
        <router-link to="/gestao/consolidacao">
          Consolidação
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteContraria)
            || authStore.ehGestor
        "
        key="contraria"
      >
        <router-link to="/gestao/contraria">
          Contrária
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteTrabalhista)
            || authStore.ehGestor
        "
        key="trabalhista"
      >
        <router-link to="/gestao/trabalhista">
          Trabalhista
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteProtocolo)
            || authStore.ehGestor
        "
        key="protocolo"
      >
        <router-link to="/gestao/protocolo">
          Protocolo
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteCda)
            || authStore.ehGestor
        "
        key="cda"
      >
        <router-link to="/gestao/cda">
          CDA
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteGca)
            || authStore.ehGestor
        "
        key="gca"
      >
        <router-link to="/gestao/gca">
          GCA
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteGdp)
            || authStore.ehGestor
        "
        key="gdp"
      >
        <router-link to="/gestao/gdp">
          GDP
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteGcpj)
            || authStore.ehGestor
        "
        key="gcpj"
      >
        <router-link to="/gestao/gcpj">
          GCPJ
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteGe)
            || authStore.ehGestor
        "
        key="ge"
      >
        <router-link to="/gestao/ge">
          GE
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteCaq)
            || authStore.ehGestor
        "
        key="caq"
      >
        <router-link to="/gestao/caq">
          CAQ
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteCdi)
            || authStore.ehGestor
        "
        key="cdi"
      >
        <router-link to="/gestao/cdi">
          CDI
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteGgp)
            || authStore.ehGestor
        "
        key="ggp"
      >
        <router-link to="/gestao/ggp">
          GGP
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="
          authStore.temPerfilAcesso(PERFIL_ACESSO.Pub) || authStore.ehGestor
        "
        key="pub"
      >
        <router-link to="/gestao/pub">
          PUB
        </router-link>
      </a-menu-item>
    </a-menu-item-group>
  </a-menu>
</template>

<style lang="less">
@import '../assets/styles/base.less';

.page-menu {
  background-color: @layout-sider-background;
  height: 100vh;
  padding-top: 16px;

  & .ant-menu-item.ant-menu-item-selected {
    background-color: #f9f9f9;
  }
}
</style>
