<script setup lang="ts">
import { onMounted } from 'vue';
import type { Compromisso } from '~/components/CompromissoForm4.vue';

interface Props
{
  compromisso: Compromisso
}

const props = defineProps<Props>();

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <CompromissoForm4
      :compromisso="props.compromisso"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Descrição"
          name="descricao"
          :rules="[
            {
              message: 'Descrição é obrigatório',
              required: true,
            },
          ]"
        >
          <a-textarea
            v-model:value="model.descricao"
            :rows="4"
          />
        </a-form-item>
      </template>
    </CompromissoForm4>
  </div>
</template>
