<script setup lang="ts">
import { RobotOutlined } from '@ant-design/icons-vue';
import bradescoSvg from '~/assets/images/bradesco.svg';
import { TipoPerfilEnum } from '~/enums/tipoPerfilEnum';
import { useAuthStore } from '~/stores/auth';

const authStore = useAuthStore();
</script>

<template>
  <Page
    menu="robos"
  >
    <div>
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/robos">
                    Robôs
                  </router-link>
                </a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <RobotOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Robôs
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container" />
            </a-col>
          </a-row>
          <!-- /Page header -->
          <a-row
            style="margin-top: 24px"
            :gutter="24"
          >
            <a-col
              v-if="authStore.temPerfilAcesso(TipoPerfilEnum.ExecutarRoboConsultaGcpj)"
              :span="6"
            >
              <router-link to="/robo/consultar-gcpj">
                <a-card>
                  <a-flex
                    align="center"
                    justify="center"
                  >
                    <a-space>
                      <img
                        :src="bradescoSvg"
                        width="56px"
                      >
                      <h3 style="margin: 0; color: rgb(153 153 153)">
                        Consultar GCPJ
                      </h3>
                    </a-space>
                  </a-flex>
                </a-card>
              </router-link>
            </a-col>
            <a-col
              v-if="authStore.temPerfilAcesso(TipoPerfilEnum.ExecutarRoboEnvioDocumentosGcpj)"
              :span="6"
            >
              <router-link to="/robo/enviar-documentos-gcpj">
                <a-card>
                  <a-flex
                    align="center"
                    justify="center"
                  >
                    <a-space>
                      <img
                        :src="bradescoSvg"
                        width="56px"
                      >
                      <h3 style="margin: 0; color: rgb(153 153 153)">
                        Enviar documentos ao GCPJ
                      </h3>
                    </a-space>
                  </a-flex>
                </a-card>
              </router-link>
            </a-col>
            <a-col
              v-if="authStore.temPerfilAcesso(TipoPerfilEnum.ExecutarRoboAtualizacaoGcpj)"
              :span="6"
            >
              <router-link to="/robo/atualizacao-gcpj">
                <a-card>
                  <a-flex
                    align="center"
                    justify="center"
                  >
                    <a-space>
                      <img
                        :src="bradescoSvg"
                        width="56px"
                      >
                      <h3 style="margin: 0; color: rgb(153 153 153)">
                        Atualização GCPJ
                      </h3>
                    </a-space>
                  </a-flex>
                </a-card>
              </router-link>
            </a-col>
            <a-col
              v-if="authStore.temPerfilAcesso(TipoPerfilEnum.ExecutarRoboEncerramentoGcpj)"
              :span="6"
            >
              <router-link to="/robo/encerramento-gcpj">
                <a-card>
                  <a-flex
                    align="center"
                    justify="center"
                  >
                    <a-space>
                      <img
                        :src="bradescoSvg"
                        width="56px"
                      >
                      <h3 style="margin: 0; color: rgb(153 153 153)">
                        Encerramento GCPJ
                      </h3>
                    </a-space>
                  </a-flex>
                </a-card>
              </router-link>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
