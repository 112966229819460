<script setup lang="ts">
import { onMounted, ref } from 'vue';

import type { Compromisso } from '~/components/CompromissoForm4.vue';
import referenciaGcpjEncerramentoSelect from '~/graphql/referenciaGcpjEncerramentoSelect.graphql';

interface Props
{
  compromisso: Compromisso
}

const props = defineProps<Props>();

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const jobStatus = ref<any>(null);
const uploadFilesGcpj = ref<any>(null);
const estaEncerrado = ref<boolean | null>(null);

async function preSubmit(model: any)
{
  if (model.estaEncerrado)
  {
    return true;
  }

  const uploadResult = await uploadFilesGcpj.value?.upload();

  if (!uploadResult.ok)
  {
    return false;
  }

  model.arquivos = uploadResult.value;

  return true;
}

async function postSubmit()
{
  jobStatus.value = 'success';
}

function estaEncerradoChange(model: any)
{
  model.descricao = null;
  estaEncerrado.value = model.estaEncerrado;
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <CompromissoForm4
      :compromisso="props.compromisso"
      :initial-model="{
        arquivosForm: [],
      }"
      :show-upload-files="estaEncerrado === true"
      :pre-submit="preSubmit"
      :post-submit="postSubmit"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Pasta foi encerrada no GCPJ?"
          name="estaEncerrado"
          :rules="[{ message: 'Informar se está encerrado ou não é obrigatório', required: true }]"
        >
          <a-radio-group
            v-model:value="model.estaEncerrado"
            @change="() => estaEncerradoChange(model)"
          >
            <a-radio-button :value="true">
              Sim
            </a-radio-button>
            <a-radio-button :value="false">
              Não
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <div v-if="model.estaEncerrado === false">
          <a-form-item
            label="Referência"
            name="referenciaGcpj"
            :rules="[
              {
                message: 'Referência é obrigatório',
                required: true,
                trigger: 'change',
                type: 'object',
              },
            ]"
          >
            <GqlSelect
              v-model:value="model.referenciaGcpj"
              :query="referenciaGcpjEncerramentoSelect"
              placeholder="Seleciona a referência do GCPJ"
            />
          </a-form-item>
          <a-form-item
            label="Andamento"
            name="andamento"
            style="padding-bottom: 0"
            :rules="[{ message: 'Andamento é obrigatório', required: true }]"
          >
            <a-textarea
              v-model:value="model.andamento"
              :rows="4"
            />
          </a-form-item>
          <UploadFilesGcpj
            ref="uploadFilesGcpj"
            v-model="model.arquivosForm"
          />
          <div style="margin-top: 24px" />
        </div>
      </template>
    </CompromissoForm4>
  </div>
</template>
