<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { GraphqlDatatableColumn } from './GqlDatatable.vue';
import enumSelectQuery from '~/graphql/enumSelect.graphql';
import type { Compromisso } from '~/components/CompromissoForm4.vue';

interface Props
{
  compromisso?: Compromisso
  pastaCodigos?: string[]
}

const props = defineProps<Props>();

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const jobStatus = ref<any>(null);
const mostrarHistoricoSolicitacoes = ref<any>('h');

const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

async function postSubmit()
{
  jobStatus.value = 'success';
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-collapse
      v-if="jobStatus !== 'success' && props.pastaCodigos?.length === 1"
      v-model:activeKey="mostrarHistoricoSolicitacoes"
    >
      <a-collapse-panel
        key="h"
        header="HISTÓRICO DE SOLICITAÇÕES"
      >
        <CompromissoGqlDatatable
          :columns="colunas"
          :show-dates-filter="false"
          :show-reload-button="false"
          :show-status-search-bar="false"
          :show-filter="false"
          :variables="{
            order: [{ dataInicio: 'DESC' }],
            where: {
              tipo: {
                eq: 64,
              },
              fluxo: {
                pastaCodigo: {
                  in: props.pastaCodigos,
                },
              },
            },
          }"
        />
      </a-collapse-panel>
    </a-collapse>

    <div style="margin-top: 16px" />

    <CompromissoForm4
      :compromisso="props.compromisso ?? {
        command: 'Encerramento.SolicitarEncerramentoCommand',
        pastas: props.pastaCodigos as string[],
      }"
      :post-submit="postSubmit"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Motivo"
          name="motivo"
          :rules="[
            {
              message: 'Motivo é obrigatório',
              required: true,
              trigger: 'change',
              type: 'object',
            },
          ]"
        >
          <GqlSelect
            v-model:value="model.motivo"
            :query="enumSelectQuery"
            :variables="{
              name: 'TipoMotivoEncerramentoEnum',
            }"
            placeholder="Seleciona a referência do GCPJ"
          />
        </a-form-item>
        <a-form-item
          label="Descrição"
          name="descricao"
          style="padding-bottom: 0"
          :rules="[{ message: 'Descrição é obrigatória', required: true }]"
        >
          <a-textarea
            v-model:value="model.descricao"
            :rows="4"
          />
        </a-form-item>
      </template>
    </CompromissoForm4>
  </div>
</template>
