query compromissoDatatable(
    $text: String
    $status: Int,
    $where: CompromissoModelFilterInput
    $order: [CompromissoModelSortInput!]
    $skip: Int
    $take: Int
) {
    data: compromissosOffsetPagination(
        texto: $text
        status: $status
        where: $where
        order: $order
        skip: $skip
        take: $take
    ) {
        items {
            codigo
            tipo
            tipoText
            status
            statusText
            dataInicio
            dataInicioText
            dataVencimento
            dataVencimentoText
            dataConclusao
            dataConclusaoText
            data
            fluxo {
                tipo
                tipoText
                tipoUrl
                pasta {
                    codigo
                    nome
                    gcpj
                    contrarioPrincipal {
                        nome
                    }
                }
            }
            responsavel {
                nome
                perfisAcesso {
                    perfil
                }
                estaDeFerias
            }
        }
        totalCount
    }
}
