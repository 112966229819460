export enum TipoPerfilEnum
{
  GrcOeste = 1,
  GrcLeste = 2,
  Contraria = 3,
  Trabalhista = 4,
  Ge = 5,
  Gcpj = 6,
  Cda = 7,
  Gdp = 8,
  Protocolo = 9,
  Advogado = 10,
  Gca = 11,
  Cdi = 12,
  GerenteContraria = 13,
  GerenteTrabalhista = 14,
  Administrador = 15,
  GerenteGrcOeste = 16,
  GerenteGrcLeste = 17,
  GerenteCdi = 18,
  GerenteCda = 19,
  GerenteGca = 20,
  GerenteProtocolo = 21,
  GerenteGe = 22,
  GerenteGdp = 23,
  GerenteGcpj = 24,
  Gestao = 25,
  PortoVelho = 26,
  Belem = 27,
  Caq = 28,
  Pub = 29,
  Grv = 30,
  GerenteCaq = 31,
  GerenteGrv = 32,
  GerenteBelem = 33,
  GerentePortoVelho = 34,
  Consolidacao = 35,
  GerenteConsolidacao = 36,
  ResponsavelNegocialImoveisEDemaisCarteiras = 37,
  ResponsavelNegocialAcre = 38,
  ResponsavelNegocialRoraima = 39,
  ResponsavelNegocialPortoVelhoVeiculosOuLeasing = 40,
  ResponsavelNegocialPortoVelhoCarteiraPrime = 41,
  ResponsavelNegocialPalmas = 42,
  ResponsavelNegocialManausOesteAcima200kNaoPrime = 43,
  ResponsavelNegocialManausOestePrime = 44,
  ResponsavelNegocialManausLesteAcima200kNaoPrime = 45,
  ResponsavelNegocialManausLestePrime = 46,
  ResponsavelAptoParaBusca = 47,
  PagarCustas = 48,
  Editor = 49,
  EditorContraria = 50,
  CallCenter = 51,
  AtenderEncerramento = 52,
  AtualizarGcpjEncerramento = 53,
  AtenderSolicitacaoCdi = 54,
  VerificarEncerramentoGcpj = 55,
  SolicitarHonorariosIrrecuperabilidade = 56,
  ReiterarEncerramento = 57,
  Ggp = 58,
  GerenteGgp = 59,
  ResponsavelProcessualImoveisEDemaisCarteiras = 60,
  ResponsavelProcessualBelem = 61,
  ResponsavelProcessualRoraima = 63,
  ResponsavelProcessualBusca = 64,
  ResponsavelProcessualPrime = 65,
  ResponsavelProcessualManausOeste = 66,
  ResponsavelProcessualManausLeste = 67,
  ResponsavelProcessualPortoVelho = 68,
  ResponsavelNegocialBelem = 69,
  ResponsavelNegocialPortoVelho = 70,
  ResponsavelNegocialBusca = 71,
  ResponsavelNegocialManausOeste = 72,
  ResponsavelNegocialManausLeste = 73,
  ResponsavelNegocialGenerica = 74,
  ResponsavelProcessualGenerica = 75,
  ResponsavelProcessualBuscaRondonia = 76,
  ResponsavelNegocialBuscaRondonia = 77,
  ResponsavelProcessualBuscaAcre = 78,
  ResponsavelNegocialBuscaAcre = 79,
  ResponsavelProcessualBuscaRoraima = 80,
  ResponsavelNegocialBuscaRoraima = 81,
  ConfirmarEnvioPlanilhaAtualizacaoDebitoAoBanco = 82,
  EnviarPlanilhaAtualizacaoDebitoAtualizada = 83,
  ConfirmarEnvioPlanilhaAtualizacaoDebitoAoBancoTipoAtualizacaoRealizacaPeloEscritorio = 84,
  EnviarPlanilhaAtualizacaoDebitoAtualizadaTipoAtualizacaoRealizacaPeloEscritorio = 85,
  ConfirmarEnvioCustasParaPagamento = 86,
  CriarCompromissoPub = 87,
  AtenderPesquisaImoveisOuObitoCdi = 88,
  CdiAtendidoPorStefanny = 89,
  CdiAtendidoPorJose = 90,
  CdiAtenderStefanny = 91,
  CdiAtenderJose = 92,
  EnviarEmailAcordoAoBanco = 93,
  AtualizarGcpjInclusaoAcordo = 94,
  RemoverProcessoPlanilhaControleAjuizamento = 95,
  IncluirAcordoCarteiraVeiculosRealizadoAntesAjuizamento = 96,
  ConcluirFluxoVerificacaoPublicacaoCapturada = 98,
  VerificarPublicacaoCapturadaOutrosDiarios = 99,
  VerificarPublicacaoCapturadaDiarioPara = 100,
  VerificarPublicacaoCapturadaDiarioAmazonas = 101,
  VerificarPublicacaoCapturadaDiarioAmapa = 102,
  VerificarPublicacaoCapturadaDiarioAcre = 103,
  VerificarPublicacaoCapturadaDiarioRondonia = 104,
  VerificarPublicacaoCapturadaDiarioRoraima = 105,
  ConcluirFluxoPub = 106,
  SolicitarVerificacaoAndamentoManual = 107, CriarAgencia = 109,
  EditarAgencia = 110,
  CancelarFluxo = 111,
  AlterarPasta = 112,
  AutomatizacaoSolicitarVerificacaoPublicacaoEmLote = 113,
  FalharFluxo = 114,
  Gce = 115,
  GerenteGce = 116,
  Gdr = 117,
  GerenteGdr = 118,
  Grcp = 119,
  GerenteGrcp = 120,
  Ferias = 121,
  FluxoVerificacaoPublicacaoConcluirFluxo = 122,
  FluxoVerificacaoAndamentoConcluirFluxo = 123,
  AutomatizacaoConcluirFluxoEmLote = 124,
  AutomatizacaoDelegarEmLote = 125,
  AutomatizacaoAlterarVencimentoEmLote = 126,
  DelegarCompromisso = 127,
  AlterarVencimentoCompromisso = 128,
  AutomatizacaoCriarCompromissoPubAPartirPublicacaoEmLote = 129,
  AutomatizacaoSolicitarAtualizacaoGcpjUltimaReferenciaEmLote = 130,
  AutomatizacaoCriarCompromissoCaqVistoriaEmLote = 131,
  FluxoCompromissoPubCriarCompromissoPub = 132,
  FluxoEnvioEmailAndamentoEnviarEmailAndamento = 133,
  FluxoRegistroEncerramentoRcbRegistrarEncerramentoRcb = 134,
  FluxoAdicionarBaseAtivaAdicionarPastaBaseAtiva = 135,
  FluxoRemoverBaseAtivaRemoverPastaBaseAtiva = 136,
  ImportarPlanilhaAlteracaoPasta = 137,
  VerificarNecessidadeCadastroPublicacaoCapturada = 139,
  VerificarNecessidadeCadastroPublicacaoCapturadaPub = 140,
  BloquearDesbloquearUsuario = 141,
  AlterarPerfilUsuario = 142,
  EnviarEmailConfirmacaoAjuizamentoAgencia = 143,
  AlterarPastaParaPreencherCamposDeep = 144,
  CdiPesquisaEnderecosEBuscaInLoco = 145,
  CdiDadosParaPequisaSiel = 146,
  CdiCertidaoDeObito = 147,
  CdiPesquisaDeImoveis = 148,
  CdiPesquisaDeVeiculos = 149,
  CdiAdvogadoCorrespondenteEmOutroEstado = 150,
  CdiLocalizadorEmOutraCidade = 151,
  CdiDadosVeicularesIncompletos = 152,
  CdiContatoTelefonico = 153,
  CdiPesquisaInventarioExtrajudicial = 154,
  CdiPesquisaJucea = 155,
  CdiCampanhasMegas = 156,
  AlterarPastaBotao = 157,
  GerenteGeral = 158,
  ImportarStatusAtualizacoesGcpjOystr = 159,
  ExportarAtualizacoesGcpjOystr = 160,
  NaoPrecisaAprovacaoSolicitacoesAoCdi = 161,
  ImportarNovasPastasPelaPlanilhaBradesco = 162,
  ExecutarRoboConsultaGcpj = 163,
  SolicitarAjuizamento = 164,
  SolicitarEncerramento = 165,
  AlterarGerenteNegocialResponsavelEmLote = 166,
  ExecutarRoboEnvioDocumentosGcpj = 167,
  GerenteAprovador = 168,
  ExecutarRoboAtualizacaoGcpj = 169,
  ExecutarRoboEncerramentoGcpj = 170,
}
