<script setup lang="ts">
import { onMounted } from 'vue';

import type { Compromisso } from '~/components/CompromissoForm4.vue';

interface Props
{
  compromisso: Compromisso
}

const props = defineProps<Props>();

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <CompromissoForm4
      :compromisso="props.compromisso"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form>
        <a-row style="margin-bottom: 24px">
          <a-col :span="24">
            <a-alert type="warning">
              <template #message>
                Caso você tenha reiterado a atualização do sistema GCPJ,
                clique no botão de confirmação
              </template>
            </a-alert>
          </a-col>
        </a-row>
      </template>
    </CompromissoForm4>
  </div>
</template>
