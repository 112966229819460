<script setup lang="ts">
import { onMounted, ref } from 'vue';
import referenciaGcpjEncerramentoSelect from '~/graphql/referenciaGcpjEncerramentoSelect.graphql';
import type { Compromisso } from '~/components/CompromissoForm4.vue';

interface Props
{
  compromisso: Compromisso
}

const props = defineProps<Props>();

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const jobStatus = ref<any>(null);
const uploadFilesGcpj = ref<any>(null);

async function preSubmit(model: any)
{
  const uploadResult = await uploadFilesGcpj.value?.upload();

  if (!uploadResult.ok)
  {
    return false;
  }

  model.arquivos = uploadResult.value;

  return true;
}

async function postSubmit()
{
  jobStatus.value = 'success';
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <CompromissoForm4
      :compromisso="props.compromisso"
      :initial-model="{
        arquivosForm: [],
      }"
      :show-upload-files="false"
      :pre-submit="preSubmit"
      :post-submit="postSubmit"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Referência"
          name="referenciaGcpj"
          :rules="[
            {
              message: 'Referência é obrigatório',
              required: true,
              trigger: 'change',
              type: 'object',
            },
          ]"
        >
          <GqlSelect
            v-model:value="model.referenciaGcpj"
            :query="referenciaGcpjEncerramentoSelect"
            placeholder="Seleciona a referência do GCPJ"
          />
        </a-form-item>
        <a-form-item
          label="Andamento"
          name="andamento"
          style="padding-bottom: 0"
          :rules="[{ message: 'Andamento é obrigatório', required: true }]"
        >
          <a-textarea
            v-model:value="model.andamento"
            :rows="4"
          />
        </a-form-item>
        <UploadFilesGcpj
          ref="uploadFilesGcpj"
          v-model="model.arquivosForm"
        />
        <div style="margin-top: 24px" />
      </template>
    </CompromissoForm4>
  </div>
</template>
